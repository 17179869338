import clsx from 'clsx';

import UnstyledLink, {
  UnstyledLinkProps,
} from '@/components/links/UnstyledLink';

interface CustomLinkProps extends UnstyledLinkProps {
  variant?: 'blue' | 'red';
}
export default function CustomLink({
  children,
  className,
  ...rest
}: CustomLinkProps) {
  return (
    <UnstyledLink
      {...rest}
      className={clsx(
        ' custom-link font-regular inline-flex items-center',
        'focus:outline-none focus-visible:rounded focus-visible:ring',
        ' text-inkanBlue-500 focus-visible:ring-inkanBlue-600',
        className
      )}
    >
      {children}
    </UnstyledLink>
  );
}
