
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import * as React from 'react';
import { RiAlarmWarningFill } from 'react-icons/ri';

import LandingPageLayout from '@/components/layouts/LandingPageLayout';
import ArrowLink from '@/components/links/ArrowLink';
import Seo from '@/components/Seo';

import { BasePageProps } from '@/types';

export default function NotFoundPage({ t }: BasePageProps) {
  return (
    <LandingPageLayout>
      <Seo templateTitle='Not Found' />

      <div className='flex flex-col items-center justify-center text-center'>
        <RiAlarmWarningFill
          size={60}
          className='drop-shadow-glow animate-flicker text-red-500'
        />
        <h1 className='mt-8 text-4xl md:text-6xl'>{t('not_found')}</h1>
        <ArrowLink className='mt-4 md:text-lg' href='/'>
          {t('back_to_home')}
        </ArrowLink>
      </div>
    </LandingPageLayout>
  );
}


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/404',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  